import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { AutoLoggedInAction, IdleTimeoutAction } from '@camino-solutions/core/app/state';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isString } from '@camino-solutions/utils/typeguard';

@Injectable()
export class AuthService {
  readonly #store = inject(Store);
  readonly #actions = inject(Actions);
  readonly #document = inject(DOCUMENT);
  protected readonly isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public readonly isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  protected readonly isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public readonly isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public readonly isAuthenticatedAndIsDoneLoading$ = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(
    filter(([isAuthenticated, isDoneLoading]) => isAuthenticated && isDoneLoading),
    take(1),
  );

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticatedSubject$,
    this.isDoneLoadingSubject$,
  ]).pipe(
    filter(values => values.every(b => b === true)),
    map(() => true),
  );

  constructor(
    private oauthService: OAuthService,
    private router: Router,
  ) {
    // Idle auto logout
    this.#actions.pipe(ofActionSuccessful(IdleTimeoutAction)).subscribe(() => this.logout());

    // Useful for debugging:
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        if (event.type === 'token_refresh_error') {
          // TODO lekezelni ha lejart valamiert a refresh token
        }
        console.error('OAuthErrorEvent Object:', event);
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    });

    // THe following cross-tab communication of fresh access tokens works usually in practice,
    // but if you need more robust handling the community has come up with ways to extend logic
    // in the library which may give you better mileage.
    //
    // See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    //
    // Until then we'll stick to this:
    window.addEventListener('storage', event => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated',
      );
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        // this.navigateToLoginPage();
        this.login();
      }
    });

    this.oauthService.events.subscribe(() =>
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken()),
    );
    this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(() => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(() => /*this.navigateToLoginPage()*/ this.login());

    this.oauthService.setupAutomaticSilentRefresh();

    this.isAuthenticatedSubject$
      .pipe(
        filter(e => e === true),
        switchMap(() => this.isDoneLoadingSubject$.pipe(filter(e => e === true))),
        take(1),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.#store.dispatch(new AutoLoggedInAction()));
  }

  public runInitialLoginSequence(): Promise<unknown> {
    // if (location.hash) {
    //   console.log('Encountered hash fragment, plotting as table...');
    //   console.table(
    //     location.hash
    //       .substr(1)
    //       .split('&')
    //       .map(kvp => kvp.split('='))
    //   );
    // }
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        // .loadDiscoveryDocumentAndTryLogin()
        .loadDiscoveryDocument()

        // For demo purposes, we pretend the previous call was very slow
        // .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1500)))

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        // .then(() => this.oauthService.tryLogin())

        .then(() => {
          // if (this.oauthService.hasValidAccessToken() /*&& this.oauthService.hasValidIdToken()*/) {
          //   return Promise.resolve();
          // }
          const invitation =
            new URLSearchParams(this.#document.defaultView?.location.search).get('invitation') ??
            '';
          if (isString(invitation) && invitation.length > 0) {
            this.oauthService.initLoginFlow('', { invitation });
          } else {
            this.oauthService.tryLogin().then(() => {
              if (
                !this.oauthService.hasValidIdToken() ||
                !this.oauthService.hasValidAccessToken()
              ) {
                this.oauthService.initLoginFlow('');
              }
            });
          }

          // debugger
          // await this.oauthService.tryLogin();

          // 2. SILENT LOGIN:
          // Try to log in via a refresh because then we can prevent
          // needing to redirect the user:
          // return this.oauthService
          //   .silentRefresh(undefined,false)
          //   .then(() => Promise.resolve())
          //   .catch(result => {
          //     // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
          //     // Only the ones where it's reasonably sure that sending the
          //     // user to the IdServer will help.
          //     const errorResponsesRequiringUserInteraction = [
          //       'interaction_required',
          //       'login_required',
          //       'account_selection_required',
          //       'consent_required',
          //     ];
          //
          //     if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
          //       // 3. ASK FOR LOGIN:
          //       // At this point we know for sure that we have to ask the
          //       // user to log in, so we redirect them to the IdServer to
          //       // enter credentials.
          //       //
          //       // Enable this to ALWAYS force a user to login.
          //       // this.login();
          //       //
          //       // Instead, we'll now do this:
          //       console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
          //       return Promise.resolve();
          //     }
          //
          //     // We can't handle the truth, just pass on the problem to the
          //     // next handler.
          //     return Promise.reject(result);
          //   });
        })
        .then(() => {
          this.isDoneLoadingSubject$.next(true);
          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oauthService.state &&
            this.oauthService.state !== 'undefined' &&
            this.oauthService.state !== 'null'
          ) {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            console.log(
              `There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`,
            );
            return this.router.navigateByUrl(stateUrl);
          } else {
            // return firstValueFrom(this.isAuthenticated$.pipe(filter(e => e === true)));
            return firstValueFrom(this.canActivateProtectedRoutes$);
          }
        })
        .catch(() => {
          this.isDoneLoadingSubject$.next(true);
        })
    );
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() {
    this.oauthService.revokeTokenAndLogout();
    // this.oauthService.logOut();
  }
  public refresh() {
    this.oauthService.silentRefresh();
  }
  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
}
